.heading-section {
  text-align: center;
}

.heading-section h1 {
  font-size: 2.5rem;
  font-weight: bold;
  color: #596981;
  margin-bottom: 0.5rem;
}

.heading-section p {
  font-size: 1.5rem;
  color: #596981;
}

.slider-image {
  width: 100%;
  height: auto;
}

.content-section {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  padding: 4rem 2rem;
  margin-bottom: 1rem;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  position: relative;
  z-index: 1;
}

.content-section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  /* Dark overlay */
  z-index: -1;
}

.read-more-container {
  margin-top: 20px;
}

.read-more-button {
  display: inline-flex;
  align-items: center;
  padding: 10px 20px;
  border: 3px solid white;
  color: #fff;
  text-decoration: none;
  border-radius: 24px;
  transition: background-color 0.3s;
}

.external-link-icon {
  margin-left: 8px;
  font-size: 16px;
}

.bg1 {
  background-image: url("../../public/assets/skills.jpg");
}

.bg2 {
  background-image: url("../../public/assets/innovators.jpg");
}

.bg3 {
  background-image: url("../../public/assets/love_stem.jpg");
}



.content {
  max-width: 800px;
  /* background: rgba(0, 0, 255, 0.3);  */
  /* background: rgba(235, 243, 242, .1); */
  /* background: rgba(59,130,246,.5); */
  backdrop-filter: blur(2px);
  border-radius: 8px;
  padding: 1rem 2rem 1rem 2rem;
  text-align: justify;
}

.content h2 {
  color: rgba(108, 246, 59, 0.65);
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  font-size: 2rem;
  font-family: ui-monospace;
}


.content h3 {
  color: rgba(108, 246, 59, 0.65);
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  font-size: 1.5rem;
  font-family: ui-monospace;
}

.content p,
.content ul {
  font-size: large;
  color: #ffffff;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.content ul {
  list-style-type: disc;
  padding-left: 2rem;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}


/* Responsive Design */
@media (max-width: 768px) {
  .heading-section h1 {
    font-size: 1.5rem;
    font-weight: bold;
    color: #596981;
    margin-bottom: 0.5rem;
  }

  .heading-section p {
    font-size: 1rem;
    color: #596981;
  }

  .content {
    backdrop-filter: blur(2px);
    border-radius: 8px;
    padding: 1rem;
    text-align: justify;
  }

  .content-section {
    padding: 4rem 1rem;
  }

  .content h2 {
    color: rgba(108, 246, 59, 0.65);
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    font-size: 1.5rem;
    font-family: ui-monospace;
  }


  .content h3 {
    color: rgba(108, 246, 59, 0.65);
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    font-size: 1.25rem;
    font-family: ui-monospace;
  }

}