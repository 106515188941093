html,
body,
#root {
  height: 100%;
  margin: 0;
  padding: 0;
}

.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-content {
  flex: 1;
}

footer {
  background-color: #1b1919;
  color: white;
  padding: 20px 0;
  text-align: center;
}