.stem-image-container {
  position: relative;
  width: 100%;
  height: auto;
}

.stem-image {
  width: 100%;
  height: auto;
  filter: brightness(50%);
}

.stem-image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 123, 255, 0.2);
  /* Blue glass shade */
  mix-blend-mode: overlay;
}

.MuiButtonBase-root {
  background-color: rgba(243, 248, 247, 0.75) !important;
}

.MuiTableHead-root {
  background-color: rgba(243, 248, 247, 0.75) !important;
  color: #596981 !important;
}

.trophy {
  color: gold;
}

.medal {
  color: rgb(238, 191, 90)
}

.level-2-section {
  display: flex;
  align-items: flex-start;
  gap: 20px;
}

.level-2-content {
  flex-grow: 1;
}

.iiit-dharwad-logo {
  width: 22%;
  height: auto;
}

@media (max-width: 600px) {
  .level-2-section {
    flex-direction: column;
    align-items: center;
  }

  .iiit-dharwad-logo {
    width: 100px;
    height: auto;
  }
}