@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

body {
    font-family: 'Roboto', sans-serif;
    margin: 0;
    padding: 4px;
    box-sizing: border-box;
}

.section {
    max-width: 1000px;
    margin: 0 auto;
    padding: 20px;
}

h2 {
    font-size: 2.5rem;
    text-align: center;
    margin-bottom: 20px;
}

.content-container {
    display: flex;
    justify-content: center;
    gap: 20px;
}

.profile-card {
    background: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: 100%;
    max-width: 350px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.profile-photo {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    margin-bottom: 20px;
}

.profile-details {
    text-align: center;
}

.profile-details h3 {
    margin: 0;
    font-size: 1.8rem;
}

.profile-details .position {
    font-size: 1.2rem;
    color: #4CAF50;
    margin: 5px 0;
}

.profile-details .description ul {
    text-align: justify;
    list-style-type: disc;
    font-size: 1rem;
    color: #555;
    margin: 10px 0;
}

.linkedin-icon {
    width: 24px;
    height: 24px;
    margin-top: 10px;
}

.about-content {
    flex: 1;
    font-size: 1.1rem;
    line-height: 1.6;
    text-align: justify;
    padding: 10px;
}

@media (max-width: 768px) {
    .content-container {
        flex-direction: column;
        align-items: center;
    }

    .profile-card {
        width: 100%;
        max-width: none;
    }

    .about-content {
        max-width: none;
    }
}
