/* Import Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

body {
    font-family: 'Roboto', sans-serif;
    margin: 0;
    box-sizing: border-box;
}

.contact-section {
    display: flex;
    flex-direction: column;
    height: 100%;
    max-width: 100%;
    margin: 0 auto;
}

.contact-header {
    text-align: center;
    background: url("../../public/assets/stem.png") no-repeat center center;
    background-size: cover;
    padding: 60px 0;
    color: white;
    font-size: 2.5rem;
    font-weight: 700;
}

.contact-container {
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
    margin: 0;
    padding: 0;
    flex-wrap: wrap;
}

.contact-info {
    flex: 1;
    background-color: #333;
    color: #fff;
    padding: 20px;
    margin-bottom: 20px; /* Add some space between the sections on mobile */
}

.contact-info h3 {
    font-size: 1.8rem;
    margin-bottom: 20px;
}

.contact-info h4 {
    font-size: 1.4rem;
    margin-top: 20px;
    margin-bottom: 10px;
}

.contact-info p {
    font-size: 1.1rem;
    margin: 5px 0;
}

.contact-info a {
    color: #007bff;
    text-decoration: none;
    font-weight: 500;
}

.contact-info a:hover {
    text-decoration: underline;
}

.map-container {
    flex: 1;
    max-width: 600px;
}

.map-container iframe {
    width: 100%;
    height: 100%;
    border: 0;
}

@media (max-width: 768px) {
    .contact-container {
        flex-direction: column;
    }

    .contact-info {
        margin-right: 0;
        margin-bottom: 0;
        width: 100%;
    }

    .map-container {
        width: 100%;
    }

    .map-container iframe {
        height: 300px;
    }
}
