.ss-header {
  height: 3.5rem;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.06);
  z-index: 4;
  background-color: white;
}

.navbar {
  z-index: 6;
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
  background-color: rgba(235, 243, 242, .7);
  background-image: linear-gradient(#fff, rgba(255, 255, 255, .2) 0%, rgba(255, 255, 255, .2) 0%);
  border-bottom: 1px solid #eee;
  align-items: center;
  width: 100%;
  height: 80px;
  padding: 0 1rem;
  display: flex;
  position: sticky;
  top: 0;
  justify-content: space-between;
}

.navbar-logo {
  color: white;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 10rem;
}

.navbar-logo .logo {
  height: auto;
  width: 100%;
  max-width: 200px;
}

.menu-icon {
  display: none;
  font-size: 2rem;
  cursor: pointer;
}

.nav-items {
  display: flex;
  list-style: none;
  text-align: center;
  justify-content: flex-end;
  flex-grow: 1;
}

.nav-items.active {
  display: block;
  position: absolute;
  top: 80px;
  left: 0;
  width: 100%;
  background-color: rgba(235, 243, 242);
  padding: 1rem 0;
}

.nav-item {
  z-index: 1000;
  display: flex;
  align-items: center;
  height: 6vh;
}

.nav-item a {
  text-decoration: none;
  color: #415f48;
  font-size: 1.1rem;
  margin-right: 2rem;
  padding: 6px 16px;
  border-radius: 5px;
}

.nav-item a:hover {
  text-decoration: underline;
}

/* Media Queries for responsiveness */
@media screen and (max-width: 768px) {
  .navbar-logo {
    width: 8rem;
  }

  .menu-icon {
    display: block;
  }

  .nav-items {
    display: none;
    flex-direction: column;
    width: 100%;
  }

  .nav-item {
    width: 100%;
    text-align: center;
    padding: 1rem 0;
  }

  .nav-item a {
    margin-right: 0;
    padding: 0.5rem;
  }
}